var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "id": "landingpage",
      "pos": "relative",
      "w": "100%",
      "mx": "auto",
      "bg": ['#F2F2F2', '#FFF'],
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "p": ['0.5rem', '1.5rem'],
      "min-height": ['calc(100vh - 62px)', '74vh']
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": [{
        label: 'Dashboard',
        href: '/'
      }, {
        label: 'Formulir Gizi',
        isCurrent: true
      }]
    }
  })], 1), _c('c-box', {
    attrs: {
      "padding-top": "30px",
      "padding-bottom": "40px"
    }
  }, [_c('c-box', {
    attrs: {
      "w": "100%",
      "max-width": "1200px",
      "mx": "auto",
      "px": ['20px', '0']
    }
  }, [_c('c-box', {
    attrs: {
      "text-align": "center"
    }
  }, [_c('c-heading', {
    attrs: {
      "as": "h2",
      "font-size": ['18px', '20px'],
      "font-weight": "700",
      "color": "black.900",
      "text-align": "center",
      "font-family": "roboto",
      "line-height": ['27px', '30px']
    }
  }, [_vm._v(" Informed Consent ")])], 1), _c('c-box', {
    attrs: {
      "w": "100%",
      "max-width": "794px",
      "mx": "auto",
      "margin-top": "30px"
    }
  }, [_c('c-box', {
    attrs: {
      "w": "100%",
      "p": ['10px', '20px'],
      "rounded": "10px",
      "background-color": ['white', 'superLightGray.900']
    }
  }, [_c('c-box', {
    staticClass: "informed-consent",
    domProps: {
      "innerHTML": _vm._s(_vm.consent)
    }
  })], 1), _c('c-checkbox', {
    attrs: {
      "align-items": "flex-start",
      "size": ['sm', 'md'].includes(_vm.currentBreakpoint) ? 'sm' : 'lg',
      "variant-color": "primary",
      "color": "#000000",
      "margin-top": "20px"
    },
    model: {
      value: _vm.isAgreeInformedConsent,
      callback: function callback($$v) {
        _vm.isAgreeInformedConsent = $$v;
      },
      expression: "isAgreeInformedConsent"
    }
  }, [_vm._v(" Saya menyetujui semua pernyataan diatas ")]), _c('c-checkbox', {
    attrs: {
      "align-items": "flex-start",
      "size": ['sm', 'md'].includes(_vm.currentBreakpoint) ? 'sm' : 'lg',
      "variant-color": "primary",
      "color": "#000000",
      "margin-top": "20px"
    },
    model: {
      value: _vm.isAgreeForResearch,
      callback: function callback($$v) {
        _vm.isAgreeForResearch = $$v;
      },
      expression: "isAgreeForResearch"
    }
  }, [_vm._v(" Saya menyetujui data saya boleh digunakan untuk keperluan pengembangan dan penelitian ilmu gizi dan kesehatan dengan identitas saya dirahasiakan ")]), _c('c-flex', {
    attrs: {
      "margin-top": "16px",
      "justify-content": "center"
    }
  }, [_c('c-button', {
    attrs: {
      "w": "100%",
      "h": "48px",
      "max-w": "500px",
      "border-radius": "100px",
      "variant-color": "primary",
      "variant": "solid",
      "loading-text": "Submitting",
      "is-disabled": _vm.isDisabled,
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v(" Selanjutnya ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }